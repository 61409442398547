// ***************************************************NZ***************************************************************************

import { Headers } from "@angular/http";

export const environment = {
  production: true,
  oAuthheaders: new Headers({
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
  }),
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json"
  }),
  mockURL: "https://tcsapi.thecreativestore.com.au/",
  auth: false
};
